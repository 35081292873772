import * as React from 'react'

import LayoutRoot from '../components/LayoutRoot'
import { Link } from 'gatsby'
import { isFrontend } from '../utils/checkSide'
import * as styles from './../styles/Search.module.scss'
import { formatDate } from '../utils/format'
import Pagination from '../components/Pagination'

export interface SearchProps {
  pageContext: {
    articles: Article[]
    pedias: Pedia[]
    videos: Video[]
  }
}

export interface SearchResult {
  id: string
  title: string
  url: string
  date: string
}

const Search = ({ pageContext: { articles, pedias, videos } }: SearchProps) => {
  const keyword = isFrontend ? decodeURI(window.location.search.split('keyword=')[1]?.split('&')[0]) : ''
  const filteredArticles = articles
    .filter(({ title }) => title.includes(keyword))
    .map(({ id, title, articleDate }) => ({
      url: `/news/${id}`,
      title: `[新闻] ${title}`,
      date: formatDate(articleDate)
    }))
  const filteredPedias = pedias
    .filter(({ title }) => title.includes(keyword))
    .map(({ id, title, pediaDate }) => ({
      url: `/pedia/${id}`,
      title: `[百科] ${title}`,
      date: formatDate(pediaDate)
    }))
  const filteredVideos = videos
    .filter(({ title }) => title.includes(keyword))
    .map(({ id, title, videoDate }) => ({
      url: `/video/${id}`,
      title: `[视频] ${title}`,
      date: formatDate(videoDate)
    }))
  const nodes = [...filteredArticles, ...filteredPedias, ...filteredVideos]
  const perPage = 10
  const [page, setPage] = React.useState(0)
  const total = nodes.length
  const pageCount = Math.ceil(total / perPage)
  return (
    <LayoutRoot>
      <>
        <div className={styles.pageContentTitle}>
          找到 {total} 个含有 “<span className={styles.highlight}>{keyword}</span>” 的结果：
        </div>
        {nodes.slice(page * perPage, (page + 1) * perPage).map(({ url, title, date }) => {
          return (
            <div key={url}>
              <Link to={url}>
                <div className={styles.title}>
                  {title.split(keyword).map((splitted, index) => (
                    <>
                      {index > 0 && <span className={styles.highlight}>{keyword}</span>}
                      {splitted}
                    </>
                  ))}
                </div>
                <div className={styles.date}>{date}</div>
              </Link>
            </div>
          )
        })}
        {pageCount > 1 && <Pagination onPageChange={setPage} pageCount={pageCount} />}
      </>
    </LayoutRoot>
  )
}

export default Search
